import * as React from "react";

import Img from "gatsby-image";

// markup
const Benefits = ({ data }) => {
  return (
    <div>
      <div className="flex">
        <Img
          fluid={data.familyTime.childImageSharp.fluid}
          alt="Bond as a family"
          className="object-cover w-1/2"
        />
        <div className="w-1/2 mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div className="md:ml-auto md:pl-10">
            <p className="mt-2 text-gray-700 text-3xl font-extrabold tracking-tight sm:text-4xl">
              Save Time
            </p>
            <p className="mt-3 text-lg text-gray-700">
              The average household spends nearly 20 hours a month doing
              laundry. Choose <b>life over laundry</b> and let us lighten your
              load!​
            </p>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="w-1/2 mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div className="md:ml-auto md:pl-10">
            <p className="mt-2 text-gray-700 text-3xl font-extrabold tracking-tight sm:text-4xl">
              Safe for your Family
            </p>
            <p className="mt-3 text-lg text-gray-900">
              We make it easy to go green by using eco-friendly solutions free
              of all the harsh ingredients. No sulphates, phosphates, optical
              brighteners, or scents – <b>just clean clothes</b>.
            </p>
          </div>
        </div>
        <Img
          fluid={data.environment.childImageSharp.fluid}
          alt="Good for the environment"
          className="object-cover  w-1/2"
        />
      </div>
    </div>
  );
};

export default Benefits;
