import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import Header from "../templates/header";
import Footer from "../templates/footer";
import ThreeStepPlan from "../components/three-step-plan";
import Benefits from "../components/benefits";
import CallToAction from "../components/call-to-action";
import ReviewsCarousel from "../components/reviews-carousel";
import StayInTouch from "../components/stay-in-touch";

import "../buttons.css";
import "./index.css";
import "./video-player.css";

import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";

import Modal from "react-modal";
import ReactPlayer from "react-player/lazy";
import Drift from "react-driftjs";

import { graphql } from "gatsby";

// markup
const IndexPage = ({ data }) => {
  const ctaRef = useRef(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);

  // https://stackoverflow.com/questions/52879362/gatsby-open-modal-window-on-button-click
  Modal.setAppElement("#___gatsby");

  function watchVideo() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    setPlaying(true);
  }

  function closeModal() {
    setPlaying(false);
    setIsOpen(false);
  }

  function executeScroll() {
    ctaRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  // Laundry Day, Done. Sit back and relax. Your clean laundry delivered in 2 days, guaranteed.
  return (
    <div>
      <Helmet>
        <title>Cloto Laundry Service</title>
        <meta name="description" content="Cloto provides wash and fold laundry services with free dropoff in the Toronto area. Guaranteed 2 day turn-around time, and a range of affordable plans."/>
      </Helmet>
      <Header></Header>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        contentLabel="Laundry Day Blues?"
        className="video-modal"
        overlayClassName="video-modal-overlay"
      >
        <ReactPlayer
          url="https://vimeo.com/503762662"
          width="100%"
          height="100%"
          playing={playing}
        />
      </Modal>
      <main>
        <div className="unhampered">
          <div className="unhampered-text">
            <h1 className="mb-2 sm:mb-2 font-bold w-32 md:w-full">
              Life Unhampered
            </h1>
            <p className="font-bold w-48 md:w-full">
              You've Got Better Things To Do Than Laundry
            </p>
            <div className="mt-8 flex flex-col md:flex-row">
              <div>
                <button
                  className="cursor-pointer w-44 mt-2 mr-2 inline-flex items-center justify-center px-3 py-3 border border-transparent text-base font-medium rounded-md clobutton"
                  onClick={(e) => watchVideo()}
                >
                  Watch the Video
                  <svg
                    className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                </button>
              </div>
              <div>
                <button
                  className="cursor-pointer w-44 mt-2 mr-2 inline-flex items-center justify-center px-3 py-3 border border-transparent text-base font-medium rounded-md clobutton"
                  onClick={(e) => executeScroll()}
                >
                  Get Started
                  <svg
                    className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <ThreeStepPlan data={data} />
        <div class="mx-auto px-4 sm:px-6 lg:px-8 bg-indigo-50 pt-8">
          <div class="max-w-3xl mx-auto">
            <h1 className="font-extrabold tracking-tight text-gray-900 text-3xl md:text-4xl mb-6 text-center">
              Why Cloto?
            </h1>
            <p className="leading-relaxed">
              Do you dream of not having to lug hampers up and down the stairs?
              Or not having to spend hours washing and drying load after load?
              Do you dream of the laundry folding itself instead of piling up on
              the bed? Do you wish your comforters could fit in your home
              washer? Wish you didn’t have to lug your stuff to the laundromat?
              Would you like an easy way to reduce waste and go green? If you
              answered ‘yes’ to any of the questions above, then you know why
              Cloto is perfect for you.{" "}
              <strong>
                Reclaim your time for the more important things in life – a life
                unhampered with Cloto.
              </strong>
            </p>
          </div>
        </div>
        <div className="mx-auto p-6 sm:p-6 lg:px-8 bg-indigo-50"></div>
        <Benefits data={data} />
        <div ref={ctaRef}>
          <CallToAction data={data} />
        </div>
        <ReviewsCarousel data={data} />
        <StayInTouch />
      </main>
      <Footer></Footer>
    </div>
  );
};

export const query = graphql`
  query ImageQuery {
    schedule: file(relativePath: { eq: "schedule.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 240, maxHeight: 240) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    relax: file(relativePath: { eq: "relax.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 240, maxHeight: 240) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    download: file(relativePath: { eq: "download.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 240, maxHeight: 240) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    unhampered: file(relativePath: { eq: "laundry-unhampered.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1800, maxHeight: 1012) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    environment: file(relativePath: { eq: "environment.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1024, maxHeight: 685) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    familyTime: file(relativePath: { eq: "family-time.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1024, maxHeight: 685) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twoTotes: file(relativePath: { eq: "two-totes.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 303, maxHeight: 157) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threeTotes: file(relativePath: { eq: "three-totes.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 303, maxHeight: 157) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
