import React, { useEffect, useState } from "react";

import Img from "gatsby-image";
import { isAndroid, isIOS } from "react-device-detect";

// markup
const ThreeStepPlan = ({ data }) => {
  const [downloadUrl, setDownloadUrl] = useState(
    "https://itunes.apple.com/us/app/cloto/id1175238344"
  );

  useEffect(() => {
    if (isAndroid) {
      setDownloadUrl(
        "https://play.google.com/store/apps/details?id=ca.cloto.userclient.android"
      );
    }
  }, [downloadUrl]);

  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
            How it Works
          </h2>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0 m-auto">
              <Img
                fluid={data.download.childImageSharp.fluid}
                alt="Life unhampered with Cloto"
                className="w-36"
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <a href={downloadUrl} className="block mt-2" target="blank">
                  <p className="text-xl font-semibold text-indigo-800 underline">
                    Download the App
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    Create an account with your email address, phone number, and
                    location. Then select a laundry plan that works for you.
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0 m-auto">
              <Img
                fluid={data.schedule.childImageSharp.fluid}
                alt="Life unhampered with Cloto"
                className="w-36"
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <div className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">
                    Schedule a Pickup
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    Fill the Clo-Totes or Clo-Bags with dirty laundry or dry
                    cleaning and confirm your pickup. We'll collect them on your
                    area's laundry day.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0 m-auto">
              <Img
                fluid={data.relax.childImageSharp.fluid}
                alt="Life unhampered with Cloto"
                className="w-36"
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <div className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">
                    Laundry Day, Done.
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    Sit back and relax. Your clean laundry delivered in 2 days,
                    guaranteed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeStepPlan;
