import * as React from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import "./reviews-carousel.css";

// markup
const ReviewsCarousel = ({ data }) => {
  const items = [
    <div className="review">
      <h3 className="underline text-indigo-700">
        <a href="https://www.google.com/maps/contrib/102794748789920414471/place/ChIJ-badjlre1IkRwNKtblMOGzE/@43.9932757,-78.9520876,11z/data=!4m6!1m5!8m4!1e1!2s102794748789920414471!3m1!1e1?hl=en-CA">
          Jessica Mills
        </a>
      </h3>
      <div className="rating five-star" />
      <p>
        We love the team at Cloto! Between a user friendly app that makes it
        easy to schedule the next pickup/delivery, invoices that are clear, and
        pickup and deliveries that are always on time (or even earlier than
        expected!),{" "}
        <strong>
          Cloto has made my life concerning laundry effortless and stress-free.
        </strong>{" "}
        Our clothes for our family of 4 come back clean and folded so neatly
        that I can just put them away as they are!
      </p>
    </div>,
    <div className="review">
      <h3 className="underline text-indigo-700">
        <a href="https://www.google.com/maps/contrib/114760677352688299441/place/ChIJ-badjlre1IkRwNKtblMOGzE/@43.88011,-79.2547013,10z/data=!4m6!1m5!8m4!1e1!2s114760677352688299441!3m1!1e1?hl=en-CA">
          Alexandria F
        </a>
      </h3>
      <div className="rating five-star" />
      <p>
        <strong>
          This service has allowed me to have so much more time with my girls.
        </strong>{" "}
        The laundry was starting to take over our house. Cloto is also fast and
        efficient and I am obsessed with the way they fold!
      </p>
    </div>,
    <div className="review">
      <h3 className="underline text-indigo-700">
        <a href="https://www.google.com/maps/contrib/113409194007663200367/place/ChIJ-badjlre1IkRwNKtblMOGzE/@46.4323735,-107.3910755,4z/data=!4m6!1m5!8m4!1e1!2s113409194007663200367!3m1!1e1?hl=en-CA">
          Karen Conchie
        </a>
      </h3>
      <div className="rating five-star" />
      <p>
        We have been using Cloto for about a month now for our cleaning business
        and we are absolutely thrilled with the services provided.{" "}
        <strong>
          The driver who drops off and picks up is always professional and
          friendly and ensures he wears his mask.{" "}
        </strong>
        All of our instructions have been followed to the letter and our cloths
        and mop heads are returned exactly the way we want them and they smell
        amazing. I highly recommend this service.
      </p>
    </div>,
    <div className="review">
      <h3 className="underline text-indigo-700">
        <a href="https://www.google.com/maps/contrib/117460633686659924647/place/ChIJ-badjlre1IkRwNKtblMOGzE/@44.0082362,-78.9874969,11z/data=!4m6!1m5!8m4!1e1!2s117460633686659924647!3m1!1e1?hl=en-CA">
          Sandra Clumpus
        </a>
      </h3>
      <div className="rating five-star" />
      <p>
        I used this service for my elderly parents. It was such a stress
        reliever for all of us. Cloto picked up the black tote each week and
        usually had the clean/dry/folded laundry back the next day!
        <strong>
          The app made it super easy to confirm pick up each week and it
          provided timely updates as to the status of their clothes.
        </strong>{" "}
        One black tote easily held their laundry for a week including a set of
        sheets and towels. I highly recommend this company.
      </p>
    </div>,
    <div className="review">
      <h3 className="underline text-indigo-700">
        <a href="https://www.facebook.com/angela.tomkinson.7/posts/10157117425665205">
          Angela Tomkinson
        </a>
      </h3>
      <div className="rating five-star" />
      <p>
        We have been using Cloto for 4 weeks and I have nothing but amazing
        things to say. We are a family of 4 and my husband and I work in
        emergency services. Obviously, this has been an especially stressful
        time for everyone around the world but for us,{" "}
        <strong>
          having something essential like laundry taken care of while we work
          overtime
        </strong>{" "}
        and navigate having our kids home while trying to take care of
        ourselves, has been such a relief.
      </p>
    </div>,
    <div className="review">
      <h3 className="underline text-indigo-700">
        <a href="https://www.facebook.com/hiroko.hirano.rmt/posts/10220875202979448">
          Hiroko Hirano
        </a>
      </h3>
      <div className="rating five-star" />
      <p>
        I am a massage therapist, and started to use Cloto service recently for
        my massage linens. Their work has been beyond my expectations. They pick
        up and deliver my laundry twice a week, and all the clean linens come
        back nicely washed and neatly folded. Laundry had been really time consuming and frustrating, but now I can
          finish my daily work without worrying about it.{" "}
        <strong>
          Highly recommend for small business owners.
        </strong>{" "}
      </p>
    </div>,
    <div className="review">
      <h3 className="underline text-indigo-700">
        <a href="https://www.facebook.com/brittney.lebel/posts/10211757858216151">
          Brittney Ernst
        </a>
      </h3>
      <div className="rating five-star" />
      <p>
        So happy with our Cloto experience so far! All the clothes come to our
        home, clean and folded beautifully.{" "}
        <strong>
          I truly believe it makes my days easier not having to worry about all
          the laundry that accumulates day to day!
        </strong>{" "}
        I truly believe it makes my days easier not having to worry about all
        the laundry that accumulates day to day!
      </p>
    </div>,
    <div className="review">
      <h3 className="underline text-indigo-700">
        <a href="https://www.facebook.com/darren.lacy.1/posts/10156456226121550:0">
          Darren L
        </a>
      </h3>
      <div className="rating five-star" />
      <p>
        Cloto is the greatest start-up ever. I have a large family of 7 people
        and we used to run the washing machine and dryer twice a day minimum,
        then the nightmare of folding the clothes. I'm saving money on time and
        detergent and dryer sheets and machine wear and tear.{" "}
        <strong>
          If you value your time and money there isn't a better solution out
          there for you.
        </strong>
      </p>
    </div>,
    <div className="review">
      <h3 className="underline text-indigo-700">
        <a href="https://www.facebook.com/sharon.barr.965/posts/10155855338126016">
          Sharon B
        </a>
      </h3>
      <div className="rating five-star" />
      <p>
        Once I had my third child I was not able to handle it all.{" "}
        <strong>
          As a working mom I depend on Cloto to give me my time back to spend
          with my family
        </strong>{" "}
        and decrease my stress over the mundane things in life like laundry.
        Thank you, Cloto, for helping us get one step closer to a work life
        balance!
      </p>
    </div>,
    <div className="review">
      <h3 className="underline text-indigo-700">
        <a href="https://goo.gl/maps/VyobVAn8opp">Nabila K</a>
      </h3>
      <div className="rating five-star" />
      <p>
        It freed up so much of my time as we are a family of seven.{" "}
        <strong>
          I found more time to be with my kids and doing other things.
        </strong>
        &nbsp;It was amazing. I was hooked.
      </p>
    </div>,
    <div className="review">
      <h3 className="underline text-indigo-700">
        <a href="https://goo.gl/maps/zCf5QfNFP8n">Reema E</a>
      </h3>
      <div className="rating five-star" />
      <p>
        This every mom’s dream. I’ve spent too many hours on laundry when I’d
        rather be doing other things.{" "}
        <strong>
          {" "}
          I literally put the dirty laundry out on my doorstep and it comes back
          clean and folded.
        </strong>
        Love it. So thankful a service like this exists.
      </p>
    </div>,
  ];

  let itemCounts = {
    512: {
      items: 1,
    },
    950: {
      items: 2,
    },
    1420: {
      items: 3,
    },
    1900: {
      items: 4,
    },
  };

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={itemCounts}
        autoPlay={true}
        autoPlayInterval={3500}
        infinite={true}
      />
    </div>
  );
};

export default ReviewsCarousel;
